import styles from './UseCasesSection.module.css';
import doctorImage from '@app/images/useCasesSectionImg.svg';
import fastlegeIcon from '@app/images/fastlege.svg';
import legevaktIcon from '@app/images/legevakt.svg';
import gastroenterologiIcon from '@app/images/gastroenterologi.svg';
import kardiologiIcon from '@app/images/kardiologi.svg';
import revmatologi from '@app/images/revmatologi.svg';
import psychologyIcon from '@app/images/psychology.svg';
import akuttMedisinIcon from '@app/images/akutt_medisin.svg';
import readMoreImg from '@app/images/useCasesReadMore.svg';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '@app/lib/i18n';

const useCases = {
  fastleger: {
    id: 1,
    title: i18n.t('sections.use_cases.gp.title'),
    icon: fastlegeIcon,
    text: i18n.t('sections.use_cases.gp.text'),
  },
  legevakt: {
    id: 2,
    title: i18n.t('sections.use_cases.emergency_room.title'),
    icon: legevaktIcon,
    text: i18n.t('sections.use_cases.emergency_room.text'),
  },
  gastroenterologi: {
    id: 3,
    title: i18n.t('sections.use_cases.gastroenterology.title'),
    icon: gastroenterologiIcon,
    text: i18n.t('sections.use_cases.gastroenterology.text'),
  },
  kardiologi: {
    id: 4,
    title: i18n.t('sections.use_cases.cardiology.title'),
    icon: kardiologiIcon,
    text: i18n.t('sections.use_cases.cardiology.text'),
  },
  revmatologi: {
    id: 5,
    title: i18n.t('sections.use_cases.rheumatology.title'),
    icon: revmatologi,
    text: i18n.t('sections.use_cases.rheumatology.text'),
  },
  akutt_medisin: {
    id: 6,
    title: i18n.t('sections.use_cases.emergency_medicine.title'),
    icon: akuttMedisinIcon,
    text: i18n.t('sections.use_cases.emergency_medicine.text'),
  },
  psykiatri: {
    id: 7,
    title: i18n.t('sections.use_cases.psychiatry.title'),
    icon: psychologyIcon,
    text: i18n.t('sections.use_cases.psychiatry.text'),
  },
};

const UseCasesSection = () => {
  const navigate = useNavigate();
  const [expandText, setExpandText] = useState<number[]>([]);
  const { t } = useTranslation();

  const handleExpandText = (id: number) => {
    if (expandText.includes(id)) {
      setExpandText(expandText.filter((index) => index !== id));
    } else {
      setExpandText([...expandText, id]);
    }
  };

  return (
    <div className={styles.useCasesSectionContainer}>
      <div className={styles.useCasesSectionInnerContainer}>
        <div className={styles.useCasesSectionTopSection}>
          <img src={doctorImage} alt={t('common.healthcare_personnel')} />
          <div className={styles.useCasesSectionTopSectionTextContainer}>
            <p className={styles.useCasesSectionTopSectionHeader}>
              {t('pages.home.use_cases.title')}
            </p>
            <p className={styles.useCasesSectionTopSectionText}>
              {t('pages.home.use_cases.subtitle')}
            </p>
          </div>
        </div>

        <div className={styles.useCasesSectionUseCasesContainer}>
          <div className={styles.useCasesSectionUseCasesRows}>
            {Object.values(useCases)
              .slice(0, 4)
              .map((useCase, index) => (
                <div key={index} className={styles.useCasesSectionUseCase}>
                  <div className={styles.useCasesSectionUseCaseHeaderContainer}>
                    <img src={useCase.icon} alt={useCase.title} />
                    <p>{useCase.title}</p>
                  </div>
                  <div
                    className={`${
                      expandText.includes(useCase.id)
                        ? styles.useCasesSectionUseCaseTextContainerExpanded
                        : styles.useCasesSectionUseCaseTextContainer
                    }`}
                  >
                    <p>{useCase.text}</p>
                  </div>
                  <p
                    className={styles.useCasesSectionUseCasesReadMore}
                    onClick={() => handleExpandText(useCase.id)}
                  >
                    {expandText.includes(useCase.id)
                      ? t('buttons.show_less')
                      : t('buttons.read_more')}
                  </p>
                </div>
              ))}
            {Object.values(useCases)
              .slice(4)
              .map((useCase, index) => (
                <div key={index} className={styles.useCasesSectionUseCase}>
                  <div className={styles.useCasesSectionUseCaseHeaderContainer}>
                    <img src={useCase.icon} alt={useCase.title} />
                    <p>{useCase.title}</p>
                  </div>
                  <div
                    className={`${
                      expandText.includes(useCase.id)
                        ? styles.useCasesSectionUseCaseTextContainerExpanded
                        : styles.useCasesSectionUseCaseTextContainer
                    }`}
                  >
                    <p>{useCase.text}</p>
                  </div>
                  <p
                    className={styles.useCasesSectionUseCasesReadMore}
                    onClick={() => handleExpandText(useCase.id)}
                  >
                    {expandText.includes(useCase.id)
                      ? t('buttons.show_less')
                      : t('buttons.read_more')}
                  </p>
                </div>
              ))}
            <div className={styles.useCasesSectionReadMore}>
              <img src={readMoreImg} alt={t('buttons.read_more')} />
              <div
                className={styles.useCasesSectionReadMoreTextContainer}
                onClick={() => navigate('/bruksomraader')}
              >
                <p>{t('buttons.read_more')}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                >
                  <path
                    d="M0.5 5.06897H9.51985L6.01654 1.56566L7.11736 0.464844L12.5 5.84748L7.11736 11.2301L6.01654 10.1293L9.51985 6.626H0.5V5.06897Z"
                    fill="#403B36"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCasesSection;
