import React from 'react';
import { FC, useState, useEffect, useRef } from 'react';
import styles from './Header.module.css';
import notelessLogo from '@app/images/noteless_logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useTranslation } from 'react-i18next';
import { PLATFORM_URL_LOGIN } from '@app/lib';
import { trackLoginClick, trackStartTrialClick } from '@app/util';

interface HeaderInterface {
  windowWidth: number;
}

const Header: FC<HeaderInterface> = ({ windowWidth }) => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const toggleMenu = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click from closing immediately
    setMenuOpen(!menuOpen);
  };

  const closeMenu = (event: Event) => {
    const eventTarget = event.target as Node;

    // Check if the event is a scroll event or a click outside the menu.
    if (
      event.type === 'scroll' ||
      (menuRef.current && !menuRef.current.contains(eventTarget))
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', closeMenu);
      window.addEventListener('scroll', closeMenu, { passive: true });
    } else {
      document.removeEventListener('mousedown', closeMenu);
      window.removeEventListener('scroll', closeMenu);
    }
    // Clean up the event listeners
    return () => {
      document.removeEventListener('mousedown', closeMenu);
      window.removeEventListener('scroll', closeMenu);
    };
  }, [menuOpen]);

  const handleChangePage = (page: string) => {
    const hasRequestedLogin = page === 'logg inn';
    if (hasRequestedLogin || page === 'prøveperiode') {
      hasRequestedLogin ? trackLoginClick() : trackStartTrialClick();
      window.open(PLATFORM_URL_LOGIN, '_blank');
    }
    if (page === 'hjem') {
      navigate('/');
      handleOpenDropdown(true);
    }
    if (page === 'hvem er vi') {
      navigate('/om-oss/hvem-er-vi');
      handleOpenDropdown(true);
    }
    if (page === 'personvern') {
      navigate('/om-oss/personvern');
      // handleOpenDropdown(true);
    }
    if (page === 'kontakt') {
      navigate('/om-oss/kontakt-oss');
      handleOpenDropdown(true);
    }
    if (page === 'bruksomraader') {
      navigate('/bruksomraader');
      handleOpenDropdown(true);
    }

    setMenuOpen(false);
  };

  const handleOpenDropdown = (close: boolean) => {
    if (close) {
      setOpenDropdown(false);
      return;
    }
    setOpenDropdown(!openDropdown);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerInnerContainer}>
        <img
          className={styles.headerLogo}
          src={notelessLogo}
          alt="Noteless"
          onClick={() => handleChangePage('hjem')}
          style={{ cursor: 'pointer' }}
        />
        {windowWidth > 920 ? (
          <div className={styles.headerNavigationMenu}>
            <div className={styles.headerNavigationMenuItems}>
              <div
                className={styles.headerNavigationMenuItemContainer}
                onClick={() => handleChangePage('hjem')}
              >
                <div
                  className={`${
                    location === '/'
                      ? styles.headerNavigationMenuItemActive
                      : styles.headerNavigationMenuItem
                  }`}
                >
                  <h3 className={styles.headerNavigationMenuItemText}>
                    {t('nav.home')}
                  </h3>
                </div>
              </div>
              <div className={styles.headerNavigationMenuItemAboutContainer}>
                <div
                  className={styles.headerNavigationMenuItemAboutTopContainers}
                >
                  <div
                    className={`${
                      location.includes('om-oss')
                        ? styles.headerNavigationMenuItemActive
                        : styles.headerNavigationMenuItem
                    }`}
                    onMouseEnter={() => handleOpenDropdown(false)}
                  >
                    <h3 className={styles.headerNavigationMenuItemText}>
                      {t('nav.about_us')}
                    </h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <path
                        d="M5.83268 8.83333L9.99935 13L14.166 8.83333"
                        stroke="#1F2E3A"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                    </svg>
                  </div>
                </div>
                {openDropdown && (
                  <div
                    className={
                      styles.headerNavigationMenuItemAboutDropdownContainers
                    }
                    onMouseLeave={() => handleOpenDropdown(true)}
                  >
                    <div
                      className={
                        styles.headerNavigationMenuItemAboutDropdownItem
                      }
                      onClick={() => handleChangePage('hvem er vi')}
                    >
                      <p>{t('nav.who')}</p>
                    </div>
                    <div
                      className={
                        styles.headerNavigationMenuItemAboutDropdownItem
                      }
                      onClick={() => handleChangePage('personvern')}
                    >
                      <p>{t('nav.privacy')}</p>
                    </div>
                    <div
                      className={
                        styles.headerNavigationMenuItemAboutDropdownItem
                      }
                      onClick={() => handleChangePage('kontakt')}
                    >
                      <p>{t('nav.contact')}</p>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={styles.headerNavigationMenuItemContainer}
                onClick={() => handleChangePage('bruksomraader')}
              >
                <div
                  className={`${
                    location === '/bruksomraader'
                      ? styles.headerNavigationMenuItemActive
                      : styles.headerNavigationMenuItem
                  }`}
                >
                  <h3 className={styles.headerNavigationMenuItemText}>
                    {t('nav.use_cases')}
                  </h3>
                </div>
              </div>
            </div>
            <div className={styles.headerNavigationMenuDivider} />
            <div className={styles.headerNavigationMenuItems}>
              <div
                className={styles.headerNavigationMenuItemContainer}
                onClick={() => handleChangePage('logg inn')}
              >
                <div className={styles.headerNavigationMenuItem}>
                  <h3 className={styles.headerNavigationMenuItemText}>
                    {t('nav.log_in')}
                  </h3>
                </div>
              </div>
              <div
                className={styles.headerNavigationMenuItemContainer}
                onClick={() => handleChangePage('prøveperiode')}
              >
                <div className={styles.headerNavigationMenuItemRegister}>
                  <h3 className={styles.headerNavigationMenuItemTextRegister}>
                    {t('buttons.trial')}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.headerNavigationMenuMobile}>
            <RxHamburgerMenu size={24} onClick={toggleMenu} />
          </div>
        )}
      </div>
      {menuOpen && (
        <div
          className={styles.headerNavigationMobileMenuContainer}
          ref={menuRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.headerNavigationMobileMenuList}>
            <div
              className={styles.headerNavigationMobileMenuItemContainer}
              onClick={() => handleChangePage('hjem')}
            >
              <div
                className={`${
                  location === '/'
                    ? styles.headerNavigationMobileMenuItemActive
                    : styles.headerNavigationMobileMenuItem
                }`}
              >
                <h3 className={styles.headerNavigationMobileMenuItemText}>
                  {t('nav.home')}
                </h3>
              </div>
            </div>
            <div className={styles.headerNavigationMobileMenuAboutContainer}>
              <div
                className={styles.headerNavigationMobileMenuAboutItem}
                onClick={() => handleOpenDropdown(false)}
              >
                <div
                  className={`${
                    location.includes('om-oss')
                      ? styles.headerNavigationMobileMenuItemActive
                      : styles.headerNavigationMobileMenuItem
                  }`}
                >
                  <h3
                    className={`${
                      openDropdown
                        ? styles.headerNavigationMobileMenuItemTextOpen
                        : styles.headerNavigationMobileMenuItemText
                    }`}
                  >
                    {t('nav.about_us')}
                  </h3>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M5.83268 8.83333L9.99935 13L14.166 8.83333"
                    stroke={openDropdown ? '#5c1b16' : '#1F2E3A'}
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                </svg>
              </div>
              {openDropdown && (
                <>
                  <div
                    className={styles.headerNavigationMobileMenuAboutItem}
                    onClick={() => handleChangePage('hvem er vi')}
                  >
                    <h3 className={styles.headerNavigationMobileMenuItemText}>
                      {t('nav.who')}
                    </h3>
                  </div>
                  <div
                    className={styles.headerNavigationMobileMenuAboutItem}
                    onClick={() => handleChangePage('personvern')}
                  >
                    <h3 className={styles.headerNavigationMobileMenuItemText}>
                      {t('nav.privacy')}
                    </h3>
                  </div>
                  <div
                    className={styles.headerNavigationMobileMenuAboutItem}
                    onClick={() => handleChangePage('kontakt')}
                  >
                    <h3 className={styles.headerNavigationMobileMenuItemText}>
                      {t('nav.contact')}
                    </h3>
                  </div>
                </>
              )}
            </div>
            <div
              className={styles.headerNavigationMobileMenuItemContainer}
              onClick={() => handleChangePage('bruksomraader')}
            >
              <div
                className={`${
                  location === '/bruksomraader'
                    ? styles.headerNavigationMobileMenuItemActive
                    : styles.headerNavigationMobileMenuItem
                }`}
              >
                <h3 className={styles.headerNavigationMobileMenuItemText}>
                  {t('nav.use_cases')}
                </h3>
              </div>
            </div>
          </div>
          <div className={styles.headerNavigationMobileMenuList}>
            <div
              className={styles.headerNavigationMobileMenuItemContainer}
              onClick={() => handleChangePage('logg inn')}
            >
              <div className={styles.headerNavigationMobileMenuItem}>
                <h3 className={styles.headerNavigationMobileMenuItemTextBold}>
                  {t('nav.log_in')}
                </h3>
              </div>
            </div>
            <div
              className={styles.headerNavigationMobileMenuItemContainer}
              onClick={() => handleChangePage('prøveperiode')}
            >
              <div className={styles.headerNavigationMobileMenuItem}>
                <h3 className={styles.headerNavigationMobileMenuItemTextBold}>
                  {t('buttons.trial')}
                </h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
