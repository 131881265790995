import React, { FC } from 'react';
import styles from './HowItWorks.module.css';
import platformMac from '@app/images/howItWorks/platform_on_mac.png';
import { useTranslation } from 'react-i18next';
import { PLATFORM_URL_LOGIN } from '@app/lib';
import { trackStartTrialClick } from '@app/util';

const HowItWorks: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.howItWorksNewContainer}>
      <div className={styles.howItWorksNewInnerContainer}>
        <div className={styles.howItWorksNewHeaderContainer}>
          <h2 className={styles.howItWorksNewHeader}>
            {t('pages.home.how_it_works.title')}
          </h2>
          <p className={styles.howItWorksNewSubtitle}>
            {t('pages.home.how_it_works.subtitle.0')}
            <br />
            {t('pages.home.how_it_works.subtitle.1')}
          </p>
        </div>
        <div className={styles.howItWorksNewBenefitsContainer}>
          <div className={styles.howItWorksNewBenefit}>
            <p className={styles.howItWorksNewBenefitHeader}>
              {t('common.hr_min', { hr: '1', min: '20' })}
            </p>
            <p className={styles.howItWorksNewBenefitText}>
              {t('pages.home.how_it_works.time_savings.0')}
              <br />
              {t('pages.home.how_it_works.time_savings.1')}
            </p>
          </div>
          <div className={styles.howItWorksNewBenefit}>
            <p className={styles.howItWorksNewBenefitHeader}>90%</p>
            <p className={styles.howItWorksNewBenefitText}>
              {t('pages.home.how_it_works.percentage')}
            </p>
          </div>
          <div className={styles.howItWorksNewBenefit}>
            <p className={styles.howItWorksNewBenefitHeader}>
              {t('pages.home.how_it_works.only_min', { min: 2 })}
            </p>
            <p className={styles.howItWorksNewBenefitText}>
              {t('pages.home.how_it_works.setup.0')}
              <br />
              {t('pages.home.how_it_works.setup.1')}
            </p>
          </div>
        </div>
        <img
          src={platformMac}
          alt={t('common.platform_on_mac')}
          className={styles.howItWorksNewPlatformImg}
        />
        <div className={styles.howItWorksNewChecksContainer}>
          {(t('pages.home.how_it_works.checks') as unknown as string[]).map(
            (check, index) => (
              <div className={styles.howItWorksNewChecks} key={index}>
                <div className={styles.howItWorksNewChecksIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="13"
                    viewBox="0 0 17 13"
                    fill="none"
                  >
                    <path
                      d="M5.7 12.025L0 6.325L1.425 4.9L5.7 9.175L14.875 0L16.3 1.425L5.7 12.025Z"
                      fill="#142115"
                    />
                  </svg>
                </div>
                <p className={styles.howItWorksNewChecksText}>{check}</p>
              </div>
            ),
          )}
        </div>
        <div className={styles.howItWorksNewDividerContainer}>
          <div className={styles.howItWorksNewDivider} />
        </div>
      </div>
      <a
        href={PLATFORM_URL_LOGIN}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
        onClick={trackStartTrialClick}
      >
        <div className={styles.howItWorksNewButton}>
          <p>{t('buttons.trial')}</p>
        </div>
      </a>
    </div>
  );
};

export default HowItWorks;
