import i18n from '@app/lib/i18n';
import { Member } from '@app/types';

export const teamMembers: Member[] = [
  {
    name: 'William Vossgård',
    position: i18n.t('common.positions.ceo'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'william.jpg',
    member_type: 'core_team',
    priority: 1,
  },
  {
    name: 'Sarankan Sivakanesan',
    position: `${i18n.t('common.positions.coo')} / ${i18n.t(
      'common.positions.doctor',
    )}`,
    bio: '',
    background_field: '',
    background_university: '',
    image: 'siva.jpg',
    member_type: 'core_team',
    priority: 2,
  },
  {
    name: 'Mikkel Dahl Slettebø',
    position: i18n.t('common.positions.cto'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'mikkel.jpg',
    member_type: 'core_team',
    priority: 3,
  },
  {
    name: 'Øystein Theodor Ødegaard-Olsen',
    position: `${i18n.t('common.positions.cmo')} / ${i18n.t(
      'common.positions.gp',
    )}`,
    bio: '',
    background_field: '',
    background_university: '',
    image: 'oystein.jpg',
    member_type: 'core_team',
    priority: 4,
  },
  {
    name: 'Michael Benjamin Robert',
    position: `${i18n.t('common.positions.cso')} / ${i18n.t(
      'common.positions.gp',
    )}`,
    bio: '',
    background_field: '',
    background_university: '',
    image: 'michael.jpg',
    member_type: 'core_team',
    priority: 5,
  },
  {
    name: 'Elisabeth Vossgård',
    position: i18n.t('common.positions.ux_designer'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'elisab.jpg',
    member_type: 'core_team',
    priority: 6,
  },
  {
    name: 'Nils-Martin Hillesund',
    position: i18n.t('common.positions.full_stack'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'nils.jpg',
    member_type: 'core_team',
    priority: 7,
  },
  {
    name: 'Øystein Nygaard',
    position: i18n.t('common.positions.ai_lead'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'oystein_nygaard.jpg',
    member_type: 'core_team',
    priority: 8,
  },
  {
    name: 'Juliane Bencsik',
    position: i18n.t('common.positions.ux_designer'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'juliane.jpg',
    member_type: 'core_team',
    priority: 9,
  },
  {
    name: 'Håvard Wikse',
    position: i18n.t('common.positions.cfo'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'havard.jpg',
    member_type: 'core_team',
    priority: 10,
  },
  {
    name: 'Pantea Joobanian',
    position: i18n.t('common.positions.full_stack'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'pantea.jpg',
    member_type: 'core_team',
    priority: 11,
  },
  {
    name: 'Annveig Richter',
    position: i18n.t('common.positions.lawyer'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'annveig.jpg',
    member_type: 'core_team',
    priority: 12,
  },
  {
    name: 'Bjarte Nore',
    position: i18n.t('common.positions.cp'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'bjarte.jpg',
    member_type: 'core_team',
    priority: 13,
  },
  {
    name: 'Kjell Garatun-Tjeldstø',
    position: i18n.t('common.positions.computer_engineer'),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'kjell.jpg',
    member_type: 'core_team',
    priority: 14,
  },
];

export const advisoryBoard: Member[] = [
  {
    name: 'Ådne Iden Høiland',
    position: i18n.t('common.positions.director_with_company', {
      company: 'Eitri Incubator',
    }),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'aadne.jpg',
    member_type: 'advisory_board',
    priority: 2,
  },
  {
    name: 'Aksel Tunold',
    position: i18n.t('common.positions.ba_with_company', { company: 'Aleap' }),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'aksel.jpg',
    member_type: 'advisory_board',
    priority: 3,
  },
  {
    name: 'Torleif Markussen Lunde',
    position: i18n.t('common.positions.leader_with_company', {
      company: 'VIS Startups',
    }),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'torleiff.jpg',
    member_type: 'advisory_board',
    priority: 1,
  },
  {
    name: 'Jørn Mikalsen',
    position: i18n.t('common.positions.founder_with_company', {
      company: 'Whereby',
    }),
    bio: '',
    background_field: '',
    background_university: '',
    image: 'jorn.jpg',
    member_type: 'advisory_board',
    priority: 4,
  },
];
