import React, { FC, useEffect, useState } from 'react';
import styles from './QuoteCardsDesktop.module.css';
import { useTranslation } from 'react-i18next';

interface Quote {
  name: string;
  position: string;
  img: string;
  quote: string;
}

interface QuotesInterface {
  quotes: Quote[];
  windowWidth: number;
}

const QuoteCardsDesktop: FC<QuotesInterface> = ({ quotes, windowWidth }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quotesPerPage, setQuotesPerPage] = useState<number>(4);
  const { t } = useTranslation();

  const cardWidth = 305 + 24;
  const translateX = currentIndex * cardWidth * -1;

  useEffect(() => {
    if (windowWidth > 1450) {
      setQuotesPerPage(4);
    } else if (windowWidth > 1150) {
      setQuotesPerPage(3);
    } else if (windowWidth > 750) {
      setQuotesPerPage(2);
    } else {
      setQuotesPerPage(1);
    }
  }, [windowWidth]);

  const goLeft = () => {
    const prevIndex = currentIndex - 1;
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex);
    }
  };

  const goRight = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < quotes.length - quotesPerPage + 1) {
      setCurrentIndex(nextIndex);
    }
  };

  return (
    <div className={styles.quotesDesktopContainer}>
      <div className={styles.quotesDesktopHeaderContainer}>
        <h2>{t('pages.home.quotes.title')}</h2>
      </div>
      <div
        className={styles.quotesDesktopInnerContainer}
        style={
          windowWidth > 1150 ? { maxWidth: '1429px' } : { maxWidth: '770px' }
        }
      >
        <div
          className={styles.quoteDesktopLeftArrowCicle}
          onClick={goLeft}
          style={
            currentIndex > 0
              ? { background: '#f4f3e7' }
              : { background: '#f2f2ed' }
          }
        >
          {currentIndex > 0 && (
            <div className={styles.quoteDesktopLeftArrowIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
              >
                <path
                  d="M7.50319 11.6482L6.06799 13.167L0.646484 6.91656L6.06812 0.679487L7.58308 2.11386H7.58398L3.16808 6.92324L7.50319 11.6482Z"
                  fill="#45433E"
                />
              </svg>
            </div>
          )}
        </div>
        <div className={styles.quoteDesktopCardsContainer}>
          {quotes.map((quote, index) => (
            <div
              key={index}
              className={styles.quoteDesktopCard}
              style={{
                transform: `translateX(${translateX}px)`,
                transition: 'transform 0.5s ease-in-out',
              }}
            >
              <div className={styles.quoteDesktopCardInnerContainer}>
                <div className={styles.quoteDesktopCardQuoteIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                  >
                    <path
                      d="M1.42045 12.0059C0.496215 11.0382 0 9.95292 0 8.19361C0 5.0978 2.2047 2.32306 5.4108 0.951172L6.2121 2.17004C3.21956 3.76571 2.63451 5.83637 2.40121 7.14192C2.88307 6.89602 3.51388 6.81022 4.13213 6.86683C5.75088 7.01455 7.02686 8.32452 7.02686 9.95292C7.02686 10.774 6.69598 11.5614 6.107 12.142C5.51803 12.7226 4.7192 13.0487 3.88627 13.0487C3.42564 13.0448 2.97041 12.9505 2.54708 12.7715C2.12375 12.5925 1.74078 12.3322 1.42045 12.0059ZM10.3936 12.0059C9.46935 11.0382 8.97314 9.95292 8.97314 8.19361C8.97314 5.0978 11.1778 2.32306 14.3839 0.951172L15.1852 2.17004C12.1927 3.76571 11.6076 5.83637 11.3743 7.14192C11.8562 6.89602 12.487 6.81022 13.1053 6.86683C14.724 7.01455 16 8.32452 16 9.95292C16 10.774 15.6691 11.5614 15.0801 12.142C14.4912 12.7226 13.6923 13.0487 12.8594 13.0487C12.3988 13.0448 11.9435 12.9505 11.5202 12.7715C11.0969 12.5925 10.7139 12.3322 10.3936 12.0059Z"
                      fill="#272421"
                    />
                  </svg>
                </div>
                <div className={styles.quoteDesktopContentTextContainer}>
                  <p>{quote.quote}</p>
                </div>
                {/* <div className={styles.quoteDesktopLesMer}>
                  {quote.quote.length > 150 && <p>Les mer</p>}
                </div> */}
                <div className={styles.quoteDesktopContentDoctorContainer}>
                  <img
                    className={styles.quoteDesktopContentDoctorImg}
                    src={quote.img}
                    alt={t('common.gp')}
                  />
                  <div className={styles.quoteDesktopContentDoctorInfo}>
                    <p className={styles.quoteDesktopContentDoctorName}>
                      {quote.name}
                    </p>
                    <p className={styles.quoteDesktopContentDoctorPosition}>
                      {quote.position}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className={styles.quoteDesktopRightArrowCicle}
          onClick={goRight}
          style={
            currentIndex < quotes.length - quotesPerPage
              ? { background: '#f4f3e7' }
              : { background: '#f2f2ed' }
          }
        >
          <div className={styles.quoteDesktopRightArrowIcon}>
            {currentIndex < quotes.length - quotesPerPage && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
              >
                <path
                  d="M0.496807 2.35176L1.93201 0.833008L7.35352 7.08344L1.93188 13.3205L0.416919 11.8861H0.416016L4.83192 7.07676L0.496807 2.35176Z"
                  fill="#45433E"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteCardsDesktop;
