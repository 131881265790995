import React, { FC } from 'react';
import styles from './SupportingPartners.module.css';
import InnovasjonNorge from '@app/images/partners/innovasjon_norge.svg';
import aleap from '@app/images/partners/aleap.png';
import eitri from '@app/images/partners/eitri.png';
import vis from '@app/images/partners/vis.png';
import ntnu_driv from '@app/images/partners/driv.png';
import nscc from '@app/images/partners/nscc.png';
import { useTranslation } from 'react-i18next';

const SupportingPartners: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.supportingPartnersContainer}>
      <div className={styles.supportingPartnersInnerContainer}>
        <div className={styles.supportingPartnersTitle}>
          <h2>{t('pages.home.partners.title')}</h2>
        </div>
        <div className={styles.supportingPartnersImagesContainer}>
          <img
            src={InnovasjonNorge}
            alt="Innovasjon Norge"
            className={styles.supportingPartnersIN}
          />
          <img
            src={vis}
            alt="Vis Innovasjon"
            className={styles.supportingPartnersVis}
          />
          <img
            src={aleap}
            alt="Aleap"
            className={styles.supportingPartnersAleap}
          />
          <img
            src={eitri}
            alt="Eitri"
            className={styles.supportingPartnersEitri}
          />
          <img
            src={ntnu_driv}
            alt="NTNU Driv"
            className={styles.supportingPartnersDriv}
          />
          <img
            src={nscc}
            alt="Norwegian Smart Care Cluster"
            className={styles.supportingPartnersNscc}
          />
        </div>
      </div>
    </div>
  );
};

export default SupportingPartners;
