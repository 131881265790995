import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import nb from './locales/nb.json';

// Import the supported languages and add to the resources object
const resources = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  nb,
};

void i18n
  .use(LanguageDetector) // Possibility to detect user language
  .use(initReactI18next)
  .init({
    resources, // Language resources
    fallbackLng: 'nb', // Default language
    debug: true,
    returnObjects: true, // Able to return objects and arrays instead of strings
  });

export default i18n;
