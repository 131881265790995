import styles from './ContactPage.module.css';
import contactIllustration from '@app/images/contact_page.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { trackContactClick } from '@app/util';

const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.contactPageContainer}>
      <div className={styles.contactPageInnerContainer}>
        <div className={styles.contactPageContentContainer}>
          <div className={styles.contactPageContentInnerContainer}>
            <div className={styles.contactPageContent}>
              <div className={styles.contactPageHeaderContainer}>
                <p className={styles.contactPageSubHeader}>
                  {t('pages.contact.sub_header')}
                </p>
                <h1 className={styles.contactPageHeader}>
                  {t('pages.contact.header')}
                </h1>
              </div>
              <div className={styles.contactPageContactInfo}>
                <p className={styles.contactPageContactInfoText}>
                  {t('pages.contact.text')}
                </p>
                <ul className={styles.contactPageContactInfoList}>
                  <li className={styles.contactPageContactInfoTextBold}>
                    {t('pages.contact.contact_us')}
                  </li>
                  <li>
                    <div
                      className={styles.contactPageContactInfoListItemWrapper}
                    >
                      <p className={styles.contactPageContactInfoText}>
                        {t('pages.contact.email')}
                        <a
                          href="mailto:post@noteless.no"
                          className={styles.contactPageContactInfoEmail}
                          onClick={trackContactClick}
                        >
                          post@noteless.no
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div
                      className={styles.contactPageContactInfoListItemWrapper}
                    >
                      <p className={styles.contactPageContactInfoText}>
                        {t('pages.contact.phone')} +47 91 73 6779
                      </p>
                    </div>
                  </li>
                </ul>
                <p className={styles.contactPageContactInfoText}>
                  {t('pages.contact.info_text')}
                </p>
              </div>
              <div className={styles.contactPageContactImageContainer}>
                <img
                  src={contactIllustration}
                  alt={t('pages.contact.contact_illustration')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
