import React, { FC } from 'react';
import {
  Hero,
  Quotes,
  SupportingPartners,
  Personvern,
  Slogan,
  HowItWorks,
  UseCasesSection,
  VideoSection,
  GetStarted,
} from '@app/components';
import { useWindowWidth } from '@app/hooks';

const MainPage: FC = () => {
  const windowWidth = useWindowWidth();

  return (
    <>
      <Hero windowWidth={windowWidth} />
      <VideoSection />
      <Quotes windowWidth={windowWidth} />
      <GetStarted />
      <HowItWorks />
      <SupportingPartners />
      <UseCasesSection />
      <Personvern />
      <Slogan />
    </>
  );
};

export default MainPage;
