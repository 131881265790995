import React, { useEffect } from 'react';
import styles from './UseCases.module.css';
import fastlegeIcon from '@app/images/fastlege.svg';
import legevaktIcon from '@app/images/legevakt.svg';
import gastroenterologiIcon from '@app/images/gastroenterologi.svg';
import kardiologiIcon from '@app/images/kardiologi.svg';
import revmatologi from '@app/images/revmatologi.svg';
import akuttMedisinIcon from '@app/images/akutt_medisin.svg';
import psychologyIcon from '@app/images/psychology.svg';
import physiotherapyIcon from '@app/images/physiotherapy.svg';
import { useTranslation } from 'react-i18next';
import i18n from '@app/lib/i18n';

const useCasesDoctors = {
  fastleger: {
    title: i18n.t('sections.use_cases.gp.title'),
    icon: fastlegeIcon,
    text: i18n.t('sections.use_cases.gp.text'),
  },
  legevakt: {
    title: i18n.t('sections.use_cases.emergency_room.title'),
    icon: legevaktIcon,
    text: i18n.t('sections.use_cases.emergency_room.text'),
  },
  gastroenterologi: {
    title: i18n.t('sections.use_cases.gastroenterology.title'),
    icon: gastroenterologiIcon,
    text: i18n.t('sections.use_cases.gastroenterology.text'),
  },
  kardiologi: {
    title: i18n.t('sections.use_cases.cardiology.title'),
    icon: kardiologiIcon,
    text: i18n.t('sections.use_cases.cardiology.text'),
  },
  revmatologi: {
    title: i18n.t('sections.use_cases.rheumatology.title'),
    icon: revmatologi,
    text: i18n.t('sections.use_cases.rheumatology.text'),
  },
  akutt_medisin: {
    title: i18n.t('sections.use_cases.emergency_medicine.title'),
    icon: akuttMedisinIcon,
    text: i18n.t('sections.use_cases.emergency_medicine.text'),
  },
};

const UseCases = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.useCasesContainer}>
      <div className={styles.useCasesInnerContainer}>
        <div className={styles.useCasesContentContainer}>
          <div className={styles.useCasesContentInnerContainer}>
            <h1 className={styles.useCasesHeader}>
              {t('pages.use_cases.title')}
            </h1>
            <p className={styles.useCasesIngress}>
              {t('pages.use_cases.ingress')}
            </p>
            <div className={styles.useCasesSections}>
              <div className={styles.useCasesSection}>
                <h3 className={styles.useCasesSectionHeader}>
                  {t('pages.use_cases.doctors_title')}
                </h3>
                <div className={styles.useCasesSectionCardContainer}>
                  {Object.values(useCasesDoctors).map((useCase, index) => (
                    <div
                      key={index}
                      className={styles.useCasesSectionDoctorCard}
                    >
                      <div
                        className={styles.useCasesSectionCardHeaderContainer}
                      >
                        <img src={useCase.icon} alt={useCase.title} />
                        <p className={styles.useCasesSectionCardHeader}>
                          {useCase.title}
                        </p>
                      </div>
                      <div className={styles.useCasesSectionCardTextContainer}>
                        <p className={styles.useCasesSectionCardText}>
                          {useCase.text}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.useCasesSection}>
                <h3 className={styles.useCasesSectionHeader}>
                  {t('pages.use_cases.psychologists_title')}
                </h3>
                <div className={styles.useCasesSectionPsychologyCard}>
                  <div className={styles.useCasesSectionCardHeaderContainer}>
                    <img
                      src={psychologyIcon}
                      alt={t('sections.use_cases.psychiatry.title')}
                    />
                    <p className={styles.useCasesSectionCardHeader}>
                      {t('sections.use_cases.psychiatry.title')}
                    </p>
                  </div>
                  <div className={styles.useCasesSectionCardTextContainer}>
                    <p className={styles.useCasesSectionCardText}>
                      {t('sections.use_cases.psychiatry.text')}
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.useCasesSection}>
                <h3 className={styles.useCasesSectionHeader}>
                  {t('pages.use_cases.physio_title')}
                </h3>
                <div className={styles.useCasesSectionPhysiotherapyCard}>
                  <div className={styles.useCasesSectionCardHeaderContainer}>
                    <img
                      src={physiotherapyIcon}
                      alt={t('sections.use_cases.physiotherapy.title')}
                    />
                    <p className={styles.useCasesSectionCardHeader}>
                      {t('sections.use_cases.physiotherapy.title')}
                    </p>
                  </div>
                  <div className={styles.useCasesSectionCardTextContainer}>
                    <p className={styles.useCasesSectionCardText}>
                      {t('sections.use_cases.physiotherapy.text')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
